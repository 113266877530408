import { Injectable, NgZone } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Camera } from '@capacitor/camera';
// declare var cordova: any;
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Device } from '@capacitor/device';
@Injectable()

export class PermissionService {
  ROOT_DIRECTORY: string;
  downloadFolderName: string;
  constructor(
    private translateService: TranslateService,
    private platform: Platform,
    private diagnostic: Diagnostic,
    private toastController: ToastController,
    public file: File,
  ) {
    this.ROOT_DIRECTORY = ''
    this.downloadFolderName = ''
    if (this.GetPlatform() == 'android') {
      this.downloadFolderName = 'Download'
      this.ROOT_DIRECTORY = this.file.externalRootDirectory;
    } else {
      this.downloadFolderName = ''
      this.ROOT_DIRECTORY = this.file.documentsDirectory
    }
    
  }
  public GetPlatform() {
    return this.platform.is('ios') ? 'ios' : 'android';
  }

  async permissionCamera() {

    console.log("clicked flag ! ahmed salem");
    

    let request_status = await Camera.requestPermissions()
    if (request_status.camera == 'denied') {
      this.AppToast(this.translateService.instant('L_noPremessionToAccessCamera'), 5000, 'e')
    }
    if (request_status.photos == 'denied') {
      this.AppToast(this.translateService.instant('L_noPremessionToAccessFiles'), 5000, 'e')
    }
  }

  async getFilePermission() {
    // let authorized = await this.diagnostic.isExternalStorageAuthorized()
    // if (!authorized) {
    //   let status = await this.diagnostic.requestExternalStorageAuthorization()
    //   if (status != this.diagnostic.permissionStatus.GRANTED) {
    //     this.AppToast(this.translateService.instant('L_noPremessionToAccessFiles'), 5000, 'e')
    //   }
    // }

    const deviceInfo = await Device.getInfo();
    const androidVersion = parseInt(deviceInfo.osVersion.split('.')[0], 10);
  
    let permission;
  
    if (androidVersion >= 13) {
      // لإصدارات Android 13 وما فوق
      permission = 'android.permission.READ_MEDIA_IMAGES';
    } else {
      // لإصدارات أقدم من Android 13
      permission = this.diagnostic.permission.READ_EXTERNAL_STORAGE;
    }
  
    try {
      let status = await this.diagnostic.getPermissionAuthorizationStatus(permission);
  
      if (status !== this.diagnostic.permissionStatus.GRANTED) {
        let newStatus = await this.diagnostic.requestRuntimePermission(permission);
  
        // if (newStatus !== this.diagnostic.permissionStatus.GRANTED) {
        //   this.AppToast(this.translateService.instant('L_noPremessionToAccessFiles'), 5000, 'e');
        //   return;
        // }
      }
  
      // الإذن ممنوح، يمكنك الآن الوصول إلى معرض الصور
  
    } catch (error) {
      console.error('Error requesting permission:', error);
      // this.AppToast(this.translateService.instant('L_noPremessionToAccessFiles'), 5000, 'e');
    }
  }


  public async AppToast(message: string, time: any = 6000, klass: any = 'i', locationtost: any = 'top') {


    const toast = await this.toastController.create({
      message: message,
      position: locationtost,
      cssClass: 'toast-class-' + klass,
      duration: time,
      buttons: [, {
        icon: 'close',
        text: '',
        handler: () => {
        }
      }
      ]
      // closeButtonText: 'Done'
    });
    await toast.present();
  }


}


// downloadfileOnOtherWay(item, filePath) {
  //   let promise = new Promise((resolve, reject) => {

  //     item.progress = 20;
  //     this.nativeHTTP.downloadFile(item.url, {}, {}, filePath).then(response => {

  //       resolve({ message: this.translateService.instant('L_downLoadMsg1'), filePath: filePath, success: true });
  //     }, err => {
  //       console.log('downloadFile err', err);
  //       if (err.status == '403') {
  //         reject(this.translateService.instant('L_errorDownLoadMsg403'));

  //       } else if (err.status == '404') {
  //         reject(this.translateService.instant('L_errorDownLoadMsg404'));
  //       } else {
  //         reject(false);
  //         if (!window.open(item.url, "_blank")) {
  //           window.location.href = item.url
  //         }
  //       }
  //     })

  //   });
  //   return promise;
  // }