import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomAlertPageModule } from './pages/common-pages/custom-alert/custom-alert.module';
import { DateTimePickerPageModule } from './pages/common-pages/date-time-picker/date-time-picker.module';

import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';

import { File } from '@awesome-cordova-plugins/file/ngx';

import { Device } from '@awesome-cordova-plugins/device/ngx'
import { TokenInterceptorService } from './providers/token-interceptor.service';
import { MathServiceImpl } from './components/math/math.service';

import { CommonApiService } from './providers/common-api.service';
import { DataStoreService } from './providers/data-store.service';
import { HttpCustomService } from './providers/http-custom.service';
import { MyAppUtilfunsService } from './providers/my-app-utilfuns.service';
import { OpenExternalUtilfuns } from './providers/open-external-utilfuns.service';

import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { VideoCapturePlus  } from '@ionic-native/video-capture-plus/ngx';

import { DisplayImgPageModule } from './pages/common-pages/display-img/display-img.module';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { PermissionService } from './providers/permissions.service';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { SafePipe } from './pipes/safe.pipe';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { EventService } from './providers/EventService.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import {environment} from "../environments/environment";
import { ApplePay } from '@ionic-native/apple-pay/ngx';

 const config: SocketIoConfig = { url: 'https://websocket.testat-app.com/', options: {} };
// import { FirebaseCrashlyticsService } from './providers/firebaseCrashlytics.service';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CustomAlertPageModule,
    DateTimePickerPageModule,
    BrowserModule,
    DisplayImgPageModule,
    NgxYoutubePlayerModule.forRoot(),
    SocketIoModule.forRoot(config),
    IonicModule.forRoot({
      swipeBackEnabled: false,
      // rippleEffect: false,
      animated: true,
      statusTap: true,
      // mode: 'ios',
      backButtonText: '',
      // navAnimation: enterAnimation
    }),

    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    Deeplinks,
    CommonApiService,
    DataStoreService,
    EventService,
    HttpCustomService,
    MathServiceImpl,
    MyAppUtilfunsService,
    // VideoCapturePlus,
    // FirebaseCrashlyticsService,
    PermissionService,
    MediaCapture,
    FileTransfer,
    OpenExternalUtilfuns,
    AppAvailability,
    StreamingMedia,
    AppVersion,
    Device,
    Network,
    InAppBrowser,
    Insomnia,
    Media,
    File,
    ApplePay,
    Diagnostic,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'androidLinKApp', useValue: 'https://play.google.com/store/apps/details?id=co.itplus.testat' },
    { provide: 'iosLinkApp', useValue: 'https://apps.apple.com/sa/app/testat/id1640011962' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
