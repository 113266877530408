import { Component,Injectable, HostListener, NgZone, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MyAppUtilfunsService } from 'src/app/providers/my-app-utilfuns.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Router } from '@angular/router';
// import { FCM } from '@ionic-native/fcm/ngx';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';

import { DataStoreService } from 'src/app/providers/data-store.service';
import { HttpCustomService } from 'src/app/providers/http-custom.service';
import { CommonApiService } from 'src/app/providers/common-api.service';
import { OpenExternalUtilfuns } from 'src/app/providers/open-external-utilfuns.service';
import { environment } from 'src/environments/environment';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
// import { FirebaseCrashlyticsService } from './providers/firebaseCrashlytics.service';
import { Device } from '@awesome-cordova-plugins/device/ngx'
import { SpeechDataService } from './providers/speech-data.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
// import { Socket } from 'ngx-socket-io';
import { userInfo } from 'os';

import { CapacitorCookies } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { CompletedataPage } from './pages/common-pages/completedata/completedata.page';
import { CompletedataService } from './shared/services/completedata.service';


@Injectable()

// import { FirebaseCrashlyticsService } from './firebaseCrashlytics.service';
// import { initializeApp } from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  lang: string;
  public env = environment;

  showCompleteDataVr:boolean = false; 
  x!:any;
 


  @HostListener('document:ionBackButton', ['$event'])
  overrideHardwareBackAction(event: any) {
    event.detail.register(100, async () => {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();

      if ((this.router.url == '/tabs/notifications' || this.router.url == "/tabs/settings" || this.router.url == "/tabs/student-profile" || this.router.url == "/tabs/teacher-profile" || this.router.url == "/tabs/parent-profile")) {
        this.navController.navigateRoot('tabs/home');
      }
      else if (this.router.url == '/tabs/home' || this.router.url == '/login') {
        this.confirmExitAppMessage();
      } else {
        this.myAppUtilfunsService.goToBack()
      }

      console.log('here')
    });

  }
  constructor(
    // private firebaseCrashlyticsService: FirebaseCrashlyticsService,

    private platform: Platform,
    private navController: NavController,
    public myAppUtilfunsService: MyAppUtilfunsService,
    private device: Device,
    private Net: Network,
    private alertController: AlertController,
    private zone: NgZone,
    public translateService: TranslateService,
    public router: Router,
    // public fcm: FCM,
    private deeplinks: Deeplinks,
    public dataStoreService: DataStoreService,
    public httpCustomService: HttpCustomService,
    private commonApiService: CommonApiService,
    public openExternalUtilfuns: OpenExternalUtilfuns,
    public appVersion: AppVersion,
    private insomnia: Insomnia,
    public speechdata: SpeechDataService,
    // private socket: Socket,
    public modalController:ModalController,
    private _CompletedataService:CompletedataService

  ) {
    this.initializeApp();
  }
  

  async initializeApp() {


    let them = this;
    this.speechdata.getObservable().subscribe((data) => {
      them.dataStoreService.speech_text = data.speechText;
      console.log(them.dataStoreService.speech_text)

    });
    this.dataStoreService.lang = await this.dataStoreService.localStorage().get('lang')
    this.languages();

    this.platform.ready().then(() => {
      this.myAppUtilfunsService.platfrom_mobile = this.platform.is('ios') ? 'ios' : 'android';
      console.log("plugins", (<any>window).plugins);
      if (this.myAppUtilfunsService.platfrom_mobile == 'android' && this.platform.is('cordova')) {
        (<any>window).plugins.preventscreenshot.disable((a) => {
          console.log("enable")
        }, (b) => {
          console.log("disable")
        });
      }

      //  this.firebaseCrashlyticsService.setErrorToFireBase('error test')
      this.openPushNotification();
      // this.cheackIfOpenFistTimeAtAll();
      // this.connection();
      this.keepAwake()



      this.checkUpdateVersion();
      


      this.commonApiService.getUsableApi()
      // ren function One Fist Time At all
      this.initialData()

    });
    this.listenToEvents();

    /********************************************************/

    this.platform.ready().then(() => {
      GoogleAuth.initialize()
    })

    
    // document.addEventListener('deviceready', function () {
    //   ApplePay.canMakePayments().then(function (message) {
    //     console.log('Apple Pay is available:', message);
    //   }).catch(function (message) {
    //     console.log('Apple Pay is not available:', message);
    //   });
    // });

   
    /********************************************************/
  }
 
  
  
  async checkUpdateVersion() {


    this.dataStoreService.settings = await this.commonApiService.getAllSettings();
    if (this.dataStoreService.settings.show_all_languages != '1' && this.dataStoreService.lang != 'ar') {
      this.dataStoreService.lang = 'ar'
      this.languages();
    }


    this.dataStoreService.app_version_number = await this.appVersion.getVersionNumber()

    // this.dataStoreService.app_version_number = '1.0.0' 
    

    this.dataStoreService.device_data = this.device;
    

    this.dataStoreService.app_version_number = this.dataStoreService.app_version_number ? this.dataStoreService.app_version_number : '0.0.0'
    if (this.dataStoreService.app_version_number < this.dataStoreService.settings.app_android_version && this.myAppUtilfunsService.platfrom_mobile == 'android') {

      if (this.dataStoreService.settings.force_update == '1') {
        this.navController.navigateRoot('force-update')
      } else {
        this.navController.navigateForward('force-update')
      }

    }
    if (this.dataStoreService.app_version_number < this.dataStoreService.settings.app_IOS_version && this.myAppUtilfunsService.platfrom_mobile == 'ios') {
      if (this.dataStoreService.settings.force_update == '1') {
        this.navController.navigateRoot('force-update')
      } else {
        this.navController.navigateForward('force-update')
      }

    }
  }



  async initialData() {
    await this.dataStoreService.initUserSomeData()
    this.navigate('')
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const pathArray = event.url.split(this.dataStoreService.deepLinkDomain);
      const appPath = pathArray.pop();
      this.deeplinks.route(appPath).subscribe(
        match => {
          console.log('Successfully matched route', match);
          // Run the navigation in the Angular zone
          this.zone.run(() => {
            if (appPath) {
              this.navigate(appPath)
            } else {
              this.navigate('/tabs/home')
            }
          });
        },
        nomatch => {
          console.error("Got a deeplink that didn't match", nomatch);
        }
      );
    });


    var this_ = this
    document.body.addEventListener('click', function (event) {
      let element = event.target as HTMLElement
      console.log(element.tagName)
      if (element.className == 'openLinkOnTxt') {
        console.log('element ===== ', element);
        this_.openExternalUtilfuns.openExternalUrl(element.textContent, 'browser', false)
      }
    });
    //get complete data value
    this._CompletedataService.showCompleteData.subscribe({
      next:(res)=> {
        console.log("res flag => ", res);
        this.showCompleteDataVr = res;
        this.showCompleteData();
      }
    })
  }


  navigate(appPath) {

    if (!this.dataStoreService.userInfo.id || this.dataStoreService.userInfo.status != '1') {
      this.dataStoreService.removeUserData()
    } else {
      this.commonApiService.getAccountData()
      this.commonApiService.getUserLoginApiData()
      if (environment.production == true) {
        this.navController.navigateRoot('tabs');
      }
      if (appPath) {
        this.navController.navigateForward(appPath);
      }
      setTimeout(() => {
        this.setDeviceToken();
      }, 3000);
    }
  }

  listenToEvents() {
    //console.log('listenToEvents', 'listenToEvents')
    this.dataStoreService.getObservableAppComponent().subscribe((event: any) => {
      if (event.event_type == 'user_login') {
        this.commonApiService.getUserLoginApiData()
        setTimeout(() => {
          this.setDeviceToken()
        }, 3000);
        //console.log(" app component event user_login ")
      }

      if (event.event_type == 'user_sign') {
        this.commonApiService.getUserLoginApiData()
        setTimeout(() => {
          this.setDeviceToken()
        }, 3000);

        //console.log(" app component event user_login ")
      }

      if (event.event_type == 'Unauthenticated') {
        this.dataStoreService.removeUserData()
      }
      if (event.event_type == 'changLang') {
        if (event.data == 'ar') {
          document.documentElement.dir = "rtl";
          // moment.locale('ar');
        } else if (event.data == 'en') {
          document.documentElement.dir = "ltr";
          // moment.locale('en');
        }
        else if (event.data == 'tu') {
          document.documentElement.dir = "ltr";
          // moment.locale('tu');
        }
        // Change Global Lang to Selected one
        this.translateService.use(event.data);
        console.log(" app component event changLang ")

      }

    });


    // this.screenOrientation.onChange().subscribe(
    //   () => {
    //     //console.log("Orientation Changed");
    //     this.Orientation = this.screenOrientation.type;
    //     //console.log(this.Orientation);
    //   }
    // );
  }

  // connection() {
  //   ////////////////////////////////////cheack connection ///////////////////////////
  //   this.Net.onConnect().subscribe(
  //     (data) => {
  //       console.log('network  connected!', data);
  //       let publishData = { 'event_type': 'connection', 'data': true }
  //       this.dataStoreService.publishSomeData(publishData);
  //       this.myAppUtilfunsService.errorMessage = ''
  //     },
  //     (error) => {
  //       //console.error(error)
  //     }
  //   );
  // }

  // async cheackIfOpenFistTimeAtAll() {
  //   let fist_time = await this.dataStoreService.localStorage().get('fistTime')
  //   setTimeout(() => {
  //     if (fist_time != 'true') {
  //       // do some thing
  //       // this.navController.navigateForward('guidelines');
  //       this.dataStoreService.localStorage().set('fistTime', "true")
  //     }
  //   }, 2000);

  // }
   
  async confirmExitAppMessage() {
    let alert = await this.alertController.create({
      header: this.translateService.instant('L_Confirm'),
      message: this.translateService.instant('L_exitAppMessage'),
      cssClass: "LoginAlertCustom",
      buttons: [

        {
          text: this.translateService.instant('L_closeApp'),
          handler: () => {
            navigator['app'].exitApp();
          },
          cssClass: "default-btn loginAlertRegisterBtn"
        },
        {
          text: this.translateService.instant('L_Cancel'),
          role: 'cancel',
          handler: () => {
          },
          cssClass: 'default-btn loginAlertCancelBtn'
        }
      ]
    });
    await alert.present();//used
  }

  async languages() {


    if (this.dataStoreService.lang) {

      if (this.dataStoreService.lang == 'ar') {
        document.documentElement.dir = "rtl";
      } else {
        document.documentElement.dir = "ltr";
      }
      document.documentElement.lang = this.dataStoreService.lang;
      this.dataStoreService.localStorage().set('lang', this.dataStoreService.lang);
      this.translateService.setDefaultLang(this.dataStoreService.lang);
    } else {

      document.documentElement.dir = "rtl";
      this.dataStoreService.lang = 'ar'

      this.dataStoreService.localStorage().set('lang', this.dataStoreService.lang);
      this.translateService.setDefaultLang(this.dataStoreService.lang);

    }
  }


  openPushNotification() {


    console.log('Initializing HomePage');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.log('Show some error ');
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success token: ', token);
      this.dataStoreService.localStorage().set('notificationToken', token.value)
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.pushNotifyConfirm(notification.data)
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.dataStoreService.navigatePushNotifcation(notification.notification.data.id, notification.notification.data.type, notification.notification.data.redirect_data, true)
      },
    );
  }

  async setDeviceToken() {
    let notificationToken = await this.dataStoreService.localStorage().get('notificationToken')
    let type = this.platform.is('ios') ? 'ios' : (this.platform.is('android') ? 'android' : 'windows');
    let info = { 'token': notificationToken, 'type': type, "lang_code": this.dataStoreService.lang }

    if (this.dataStoreService.userInfo.id) {
      this.httpCustomService.post('notifications.php?action=setDeviceToken', info)
        .subscribe((data) => {
          console.log(data);

        }, err => {
          console.log(err)
        });
    }

  }
  


  async pushNotifyConfirm(comeData) {
    console.log(comeData);

    let alert = await this.alertController.create({
      header: this.translateService.instant('L_notification'),
      message: comeData.body,
      cssClass: "LoginAlertCustom",
      buttons: [
        {
          text: this.translateService.instant('L_show'),
          handler: () => {
            this.dataStoreService.navigatePushNotifcation(comeData.id, comeData.type, comeData.redirect_data, true)
          },
          cssClass: "default-btn loginAlertRegisterBtn"
        },
        {
          role: 'cancel',
          text: this.translateService.instant('L_close'),
          handler: () => {
            console.log('Cancel clicked');

          },
          cssClass: 'default-btn loginAlertCancelBtn'
        }
      ]
    });
    await alert.present();


  }


  keepAwake() {
    this.insomnia.keepAwake()
      .then(
        () => console.log('success'),
        () => console.log('error')
      );
  }

  async showCompleteData() {
    if (this.showCompleteDataVr) {
      const modal = await this.modalController.create({
        component: CompletedataPage,
        cssClass: 'members_modal',
        initialBreakpoint: 0.7,
        breakpoints: [0, 0.7, 1]
      });
      return await modal.present(); 
    }
  }


}

