import { Injectable, Injector } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { DataStoreService } from "./data-store.service";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { MyAppUtilfunsService } from "./my-app-utilfuns.service";
import { TranslateService } from "@ngx-translate/core";
import { NavController, ToastController } from "@ionic/angular";
import { environment } from "../../environments/environment";
import * as bcrypt from "bcryptjs";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  public env = environment;

  constructor(
    private injector: Injector,
    private myAppUtilfunsService: MyAppUtilfunsService,
    private dataStoreService: DataStoreService,
    private translateService: TranslateService,
    private navController: NavController
  ) {}


  intercept(req, next) {
    let dataStoreService = this.injector.get(DataStoreService);
    dataStoreService.authentication_code = dataStoreService.authentication_code
      ? dataStoreService.authentication_code
      : "";

    console.log(dataStoreService.settings.api_secrit_key_on);
    let is_test_mode = this.env.production ? "no" : "yes";
    let pass =
      dataStoreService.settings.api_secrit_key_on == "0"
        ? ""
        : bcrypt.hashSync("rAm");
    // let pass ='asdasfas da sda '
    let tokenizedReq = req.clone({
      setHeaders: {
        "User-Token": `${dataStoreService.authentication_code}`,
        "Front-Lang": dataStoreService.lang,
        "Access-Control-Allow-Origin": "*",
        "Set-From": "mobile",
        "Is-Test-Mode": is_test_mode,
        "Secret-Api": pass,
        "App-Version": dataStoreService.app_version_number
          ? dataStoreService.app_version_number
          : "none",
        "App-Model": dataStoreService.device_data.model
          ? dataStoreService.device_data.model
          : "none",
        "App-Uuid": dataStoreService.device_data.uuid
          ? dataStoreService.device_data.uuid
          : "none",
        "App-Platform": dataStoreService.device_data.platform
          ? dataStoreService.device_data.platform
          : "none",
        "App-Platform-Version": dataStoreService.device_data.version
          ? dataStoreService.device_data.version
          : "none",
        "App-Manufacturer": dataStoreService.device_data.manufacturer
          ? dataStoreService.device_data.manufacturer
          : "none",
        "App-Serial": dataStoreService.device_data.serial
          ? dataStoreService.device_data.serial
          : "none",
      },
    });

    return next.handle(tokenizedReq).pipe(
      map((res: any) => {
        console.log("this.res (interceptor) => ", res);
        if (res.status == 200) {
          this.myAppUtilfunsService.errorMessage = "";
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        this.showError(error);
        return throwError("");
      })
    );
  }

  showError(err) {
    console.log("err =>1<= ", err);

    if (err.status == 0) {
      this.myAppUtilfunsService.errorMessage =
        this.translateService.instant("L_errorConnection");
    } else if (err.status == 401) {
      let publishData = { event_type: "Unauthenticated" };
      this.dataStoreService.publishAppComponent(publishData);
    } else if (err.status == 403) {
      this.myAppUtilfunsService.customAlert(
        this.translateService.instant("L_finishSubsicription"),
        "logo2.png"
      );
      if (this.dataStoreService.showSubsicripe) {
        this.navController.navigateForward("prices");
      }
    } else if (err.status == 404) {
      this.myAppUtilfunsService.customAlert(
        err.error.message,
        "logo2.png",
        false,
        true
      );
      // this.navController.back();
    } else {
      
      if (err.error) {
        if (err.error.message) {
          this.myAppUtilfunsService.customAlert(err.error.message, "logo2.png");
        } else {
          this.myAppUtilfunsService.errorMessage =
            this.translateService.instant("L_someErrorHappen");
        }
      }
    }
  }
  
}
