import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonInput, LoadingController, NavController, Platform } from '@ionic/angular';
import { CommonApiService } from 'src/app/providers/common-api.service';
import { DataStoreService } from 'src/app/providers/data-store.service';
import { HttpCustomService } from 'src/app/providers/http-custom.service';
import { loginI, signUpI } from 'src/app/providers/interfaces';
import { MyAppUtilfunsService } from 'src/app/providers/my-app-utilfuns.service';
import { CompletedataService } from 'src/app/shared/services/completedata.service';
import {
  FacebookLogin,
} from '@capacitor-community/facebook-login';

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { TranslateService } from '@ngx-translate/core';
import { OpenExternalUtilfuns } from 'src/app/providers/open-external-utilfuns.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-completedata',
  templateUrl: './completedata.page.html',
  styleUrls: ['./completedata.page.scss'],
})
export class CompletedataPage implements OnInit {

  passwordSign_show: boolean = false;
  confirmPassSign_show: boolean = false;
  myForm!:FormGroup;
  passwordEqual:boolean = false;
  userData:any = {};
  emailAlreadyUse:boolean = false;
  curentStep:number = 1;
  confirmSignUpCodeMobileLoader: boolean = false;
  isTeacher!:boolean;
  snedEmailLoading!:boolean;
  isWeb: boolean;
  
  showAppleSignIn: any;
  show_sub: boolean = true;
  userExist!:boolean;
  step:number;
  checkUserLoader:boolean;
  loginLoader!:boolean;

  verifyDigitSt = new FormControl('');
  verifyDigitNd = new FormControl('');
  verifyDigitRd = new FormControl('');
  verifyDigitf = new FormControl('');
  verifyDigits = new FormControl('');
  verifyDigitTh= new FormControl('');

  loginError = {
    email: '',
    password: '',
  }

  virCode: any;

  login: loginI = {
    email: undefined,
    password: undefined,
    signup_way:undefined
  }

  signup: signUpI = {
    full_name: undefined,
    country_code: undefined,
    mobile: undefined,
    email: undefined,
    password: undefined,
    confirm_password: undefined,
    user_type: undefined,
    country_id: 0,
    stage_id: 0,
    grade_id: 0,
    field_of_study_id: 0,
    teacher_subjects: [],
    students_for_parent: [],
    acceptCondition: false,
  }
   
  @ViewChild('input1', { read: IonInput, static: false }) input1: IonInput;
  @ViewChild('input2', { read: IonInput, static: false }) input2: IonInput;
  @ViewChild('input3', { read: IonInput, static: false }) input3: IonInput;
  @ViewChild('input4', { read: IonInput, static: false }) input4: IonInput;
  @ViewChild('input5', { read: IonInput, static: false }) input5: IonInput;
  @ViewChild('input6', { read: IonInput, static: false }) input6: IonInput;
  ngZone: any;


  constructor(
    public dataStoreService:DataStoreService,
    private fb:FormBuilder,
    private CommonApiService:CommonApiService,
    public myAppUtilfunsService:MyAppUtilfunsService,
    public httpCustomService: HttpCustomService,
    private _CompletedataService:CompletedataService,
    private navController:NavController,
    public platform:Platform,
    public loadingController: LoadingController,
    public translateService: TranslateService,
    public alertController: AlertController,
    private openExternalUtilfuns: OpenExternalUtilfuns,
    private router: Router,
  ) {
   this.myForm =  this.fb.group({
      country_code:['+20'],
      email: ['', Validators.required],
      mobile:[''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password:['', Validators.required],
      signup_way: ['sign_up'],
      user_id:['']
    },
    { validator: this.passwordMatchValidator })
    this.showAppleSignIn = this.platform.is('ios')
    this.FacebookCurrentAccessToken();
    this.isWeb = !this.platform.is('android') && !this.platform.is('ios');
    this.show_sub = this.dataStoreService.showSubsicripe();

    /***************************************/
    
    GoogleAuth.initialize({
      clientId: '331025337579-tmjs2kpeg74sv708odhmdkksd7632lge.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });

    /***************************************/
   }

  ngOnInit() {

  }


  ionViewWillEnter() {
    this.getUserData();
    this.myForm.get('user_id').setValue(this.userData.id);
    this.myAppUtilfunsService.AppToast('أكمل إدخال البيانات للدخول', 3000, 'e');
  }

  dismissLoader() {
    setTimeout(() => {
      this.loadingController.dismiss().then((response) => {
      }).catch((err) => {
        console.log('Error occured : ', err);
      });
    }, 200);
  }
  getRandomStr(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }
  
  applesignIn() {
    const hashedNonceHex = this.getRandomStr(10); // see next function
    this.presentLoader()
    let options: SignInWithAppleOptions = {
      clientId: 'co.itplus.testat',
      redirectURI: '',
      scopes: 'email name',
      state: '1256',
      nonce: hashedNonceHex
    };

    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        // Handle user information
        console.log(JSON.stringify(result))
        this.dataStoreService.accessToken = result.response.user
        this.loginError.email = ''
        if (result.response.email == null) {
          let data =
            { "social_access_token": result.response.user }
          this.httpCustomService.post('users.php?action=loginBySocialAccessToken', data).subscribe((res) => {
            if (res.success) {
              // this.userExist = true;
              if (this.dataStoreService.relatedAccountcaching.length > 1) {
                this.dataStoreService.relatedAccountcaching.push(res.data)
                this.navController.navigateForward(['/switch-accounts']) 
                     }
                     else
                     {  
                      this.dataStoreService.setUserData(res.data)
                  
                      let publishData = { 'event_type': 'user_login', 'data': res }
                      this.dataStoreService.publishAppComponent(publishData);
                      this.navController.navigateRoot('tabs');
                     }   
                     this.dismissLoader()    
                     //  this.goToStep2()
              // this.checkUserLoader = false
            } else {
              this.userExist = false;
              this.step = 3
              this.checkUserLoader = false
              this.dismissLoader()
              if (res.message == 'لا يوجد حساب مرتبط بالبريد الالكترونى') {
                this.myForm.get('signup_way').patchValue('apple');
                this.myForm.get('email').patchValue(this.login.email);
              }
            }
          }, err => {
            this.checkUserLoader = false
            this.dismissLoader()
            console.log(err);
          });
        }
        else {
          var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          this.login.email = result.response.email
          // if (!this.login.country_code) {
          //   this.loginError.email = this.translateService.instant('L_selectCountryCode');
          // }
          if (!this.login.email) {
            this.loginError.email = this.translateService.instant('L_enteYourEmail');
            this.dismissLoader()
          }
          else if (!re.test(this.login.email)) {
            this.loginError.email = this.translateService.instant('L_enteCorrectEmail');
            this.dismissLoader()
          }

          else {

            this.checkUserLoader = true
            this.login["social_access_token"] = result.response.user
            this.httpCustomService.post('users.php?action=cheackUserByMobile', this.login).subscribe((res) => {
              if (res.success) {
                // this.userExist = true;
                this.onLogin("apple")         //  this.goToStep2()
                // this.checkUserLoader = false
              } else {
                this.userExist = false;
                this.step = 3
                this.checkUserLoader = false
                this.dismissLoader()
                // go to sign up
              }
            }, err => {
              this.checkUserLoader = false
              this.dismissLoader()
              console.log(err)


            });
          }
        }

        // Validate token with server and create new session
      })
      .catch(error => {
        console.log(JSON.stringify(error))

        // Handle error
      });
  }


 
  resetCode() {
    this.virCode = ''
    this.verifyDigitSt.setValue('');
    this.verifyDigitNd.setValue('');
    this.verifyDigitRd.setValue('');
    this.verifyDigitTh.setValue('');
    this.verifyDigitf.setValue('');
    this.verifyDigits.setValue('');
  }

  async FacebookCurrentAccessToken() {
    const result = await (
      FacebookLogin.getCurrentAccessToken()
    );

    if (result.accessToken) {
      console.log(`Facebook access token is ${result.accessToken.token}`);
    }
  }

  async FacebookLogout() {
    await FacebookLogin.logout();
    console.log('Facebook logout');
  }
  
  presentLoader() {
    this.loadingController.create({
      message: this.translateService.instant("L_Loading")
    }).then((response) => {
      response.present();
    });
  }
  

  async FacebookLogin() {
    this.presentLoader()
    await FacebookLogin.initialize({ appId: '679072710702330' });
    this.FacebookLogout();
    const FACEBOOK_PERMISSIONS = [
      "email",
      "public_profile"
    ];
    const result = await (
      FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    );


    if (result.accessToken) {
      // Login successful.
      console.log(JSON.stringify(result))
      console.log(`Facebook access token is ${result.accessToken.token}`);
      const resultemail = await FacebookLogin.getProfile<{
        email: string;
      }>({ fields: ['email'] });
      console.log(`Facebook user's email is ${resultemail.email}`);
      this.dataStoreService.accessToken = result.accessToken.userId
      this.loginError.email = ''
      if (resultemail.email == null) {
        let data =
          { "social_access_token": result.accessToken.userId }
        this.httpCustomService.post('users.php?action=loginBySocialAccessToken', data).subscribe((res) => {
          if (res.success) {
            // this.userExist = true;
            if (this.dataStoreService.relatedAccountcaching.length > 1) {
              this.dataStoreService.relatedAccountcaching.push(res.data)
              this.navController.navigateForward(['/switch-accounts'])
              }
              else{
                this.dataStoreService.setUserData(res.data)
               
                let publishData = { 'event_type': 'user_login', 'data': res }
                this.dataStoreService.publishAppComponent(publishData);
                this.navController.navigateRoot('tabs');
              }
              this.dismissLoader()
              // this.checkUserLoader = false
            } else {
              this.userExist = false;
              this.step=3
              this.checkUserLoader = false
              this.dismissLoader()
              if (res.message == 'لا يوجد حساب مرتبط بالبريد الالكترونى') {
                this.myForm.get('signup_way').patchValue('facebook');
                this.myForm.get('email').patchValue(this.login.email);
              }
            }
          }, err => {
            this.checkUserLoader = false
            console.log(err)
            this.dismissLoader()
    
          }); 
        }
      
      else{
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.login.email = resultemail.email;
        this.signup.email = resultemail.email;
        // if (!this.login.country_code) {signup
        //   this.loginError.email = this.translateService.instant('L_selectCountryCode');
        // }
        if (!this.login.email) {
          this.loginError.email = this.translateService.instant('L_enteYourEmail');
          this.dismissLoader()
        }
        else if (!re.test(this.login.email)) {
          this.loginError.email = this.translateService.instant('L_enteCorrectEmail');
          this.dismissLoader()
        }

        else {

          this.checkUserLoader = true
          this.login["social_access_token"] = result.accessToken.userId
          this.httpCustomService.post('users.php?action=cheackUserByMobile', this.login).subscribe((res) => {
            if (res.success) {
              // this.userExist = true;
              this.onLogin("facebook")         //  this.goToStep2()
              // this.checkUserLoader = false
            } else {
              this.userExist = false;
              this.step = 3
              this.checkUserLoader = false
              this.dismissLoader()
              // go to sign up
            }
          }, err => {
            this.checkUserLoader = false
            console.log(err)
            this.dismissLoader()


          });
        }
      }

    }
  }

  async contactAdminAlert(msg, user_id, full_mobile) {

    let msg_to_admin = this.translateService.instant('L_msg_to_wats_to_admin1')
    msg_to_admin += "%0a"
    msg_to_admin += "%0a"

    msg_to_admin += this.translateService.instant('L_msg_to_wats_to_admin2')
    msg_to_admin += "%0a"
    msg_to_admin += "%0a"

    msg_to_admin += this.translateService.instant('L_msg_to_wats_to_admin3')
    msg_to_admin += "%0a"
    msg_to_admin += this.translateService.instant('L_ID') + " : " + user_id
    msg_to_admin += "%0a"
    msg_to_admin += this.translateService.instant('L_mobile') + " : " + full_mobile



    const alert = await this.alertController.create({
      // header: this.translateService.instant('L_welcome'),
      // subHeader: msg,
      message: msg + "\n" + this.dataStoreService.settings.whatsapp,
      cssClass: "LoginAlertCustom",
      buttons: [
        {
          text: this.translateService.instant('L_contactCustomerServices'),

          handler: (blah) => {
            this.openExternalUtilfuns.openExternalUrl(this.dataStoreService.settings.whatsapp, 'whatsapp', msg_to_admin);
          },
           cssClass: "default-btn loginAlertRegisterBtn"
        },
      ]
    });
    await alert.present();
  }
  googleSignOut() {
    GoogleAuth.signOut().then(() => {
      this.router.navigate(['home']);
    });
  }

  GooglesignIn() {
    
    this.platform.ready().then(async () => {

    this.googleSignOut();

    const user = await GoogleAuth.signIn();

  
      if (user) {
        
        this.presentLoader()
        this.dataStoreService.accessToken = user.id
        this.loginError.email = ''
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.login.email = user.email
        this.signup.email = user.email
        if (!this.login.email) {
          
          this.loginError.email = this.translateService.instant('L_enteYourEmail');
          this.dismissLoader()
        }
        else if (!re.test(this.login.email)) {
          
          this.loginError.email = this.translateService.instant('L_enteCorrectEmail');
          this.dismissLoader()
        }
        else {

          this.checkUserLoader = true
          this.login["social_access_token"] = user.id
          this.httpCustomService.post('users.php?action=cheackUserByMobile', this.login).subscribe(async (res) => {
            if (res.success) {
              // this.userExist = true;
              this.onLogin("google")
              //  this.goToStep2()
              // this.checkUserLoader = false
            } else {
              
              this.userExist = false;
              this.step = 3
              this.checkUserLoader = false
              this.dismissLoader()
              
              if (res.message == 'لا يوجد حساب مرتبط بالبريد الالكترونى') {
                this.myForm.get('signup_way').patchValue('google');
                this.myForm.get('email').patchValue(this.login.email);
              }

              // go to sign up
            }
          }, async err => {
            this.checkUserLoader = false
            console.log(err)
            this.dismissLoader()
          });
        }

      }
      
    })
  }
  
  
  
  onLogin(value) {

    this.signup["social_access_token"] = undefined
    this.loginError.email = ''
    this.loginError.password = ''
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log("login", this.login)
    // if (!this.login.country_code) {
    //   this.loginError.email = this.translateService.instant('L_selectCountryCode');
    // }
    if (value == "login") {
      this.presentLoader()
      if (!this.login.email) {
        this.loginError.email = this.translateService.instant('L_enteYourEmail');
        this.dismissLoader()
      }
      else if (!re.test(this.login.email)) {
        this.loginError.email = this.translateService.instant('L_enteCorrectEmail');
        this.dismissLoader()
      }
      // else if (this.login.mobile.length != this.selectedCountry.valid_count_of_mobile_number) {
      //   this.loginError.email = this.translateService.instant('L_enteYourMobile-') + " (" + this.selectedCountry.valid_count_of_mobile_number + ") " + this.translateService.instant('L_numberCount')
      // }
      // else if (!Number(this.login.mobile) || this.login.mobile.trim().indexOf(' ') >= 0) {
      //   this.loginError.email = this.translateService.instant('L_enteYourMobileInNumberformat');
      // }
      else if (!this.login.password) {
        this.loginError.password = this.translateService.instant('L_enteYourPassword');
        this.dismissLoader()
      }
      else {
        this.loginLoader = true;
        this.httpCustomService.post('users.php?action=login', this.login).subscribe(res => {

        this.loginLoader = false;
        if (res.success) {
          console.log(res.data);
          if (res.data.related_accounts?.length > 1) {
            this.dataStoreService.relatedAccountcaching = res.data.related_accounts 
            this.dismissLoader()
            this.navController.navigateForward(['/switch-accounts'])
            
           
          } else {
            this.dataStoreService.setUserData(res.data)
         
            let publishData = { 'event_type': 'user_login', 'data': res }
            this.dataStoreService.publishAppComponent(publishData);
            this.dismissLoader()
            this.navController.navigateRoot('tabs');
          }
          // goto account switch
          // this.goToSomePlace()
        } else {
          if (res.mobile_is_not_valid) {
            this.contactAdminAlert(res.message, res.id, res.country_code + res.mobile)
            this.dismissLoader()
          } else {
            res.message ? this.myAppUtilfunsService.customAlert(res.message, 'logo2.png') : '';
            this.dismissLoader()
          }

          }
        }, err => {
          this.loginLoader = false;
          this.dismissLoader()


        }
        );
      }
    }
    else if (value == "google" || value == "facebook" || value == "apple") {
      this.loginLoader = true;
      this.httpCustomService.post('users.php?action=login', this.login).subscribe(res => {

        this.loginLoader = false;
        if (res.success) {
          console.log(res.data);
          if (res.data.related_accounts?.length > 1) {
            this.dataStoreService.relatedAccountcaching = res.data.related_accounts
            this.dismissLoader()
            this.navController.navigateForward(['/switch-accounts'])

          } else {
            this.dataStoreService.setUserData(res.data)
            let publishData = { 'event_type': 'user_login', 'data': res }
            this.dataStoreService.publishAppComponent(publishData);
            this.dismissLoader()
            this.navController.navigateRoot('tabs');
          }
          // goto account switch
          // this.goToSomePlace()
        } else {
          if (res.mobile_is_not_valid) {
            this.contactAdminAlert(res.message, res.id, res.country_code + res.mobile)
          } else {
            res.message ? this.myAppUtilfunsService.customAlert(res.message, 'logo2.png') : '';
          }

        }
      }, err => {
        this.loginLoader = false;
      }
      );
    }
  }

  getUserData():void {
    this.userData = JSON.parse(localStorage.getItem('userInfo'));
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirm_password = formGroup.get('confirm_password')?.value;

    if (password !== confirm_password) {
      formGroup.get('confirm_password')?.setErrors({ passwordMismatch: true });
    } else {
      formGroup.get('confirm_password')?.setErrors(null);
    }
  }

  togglePasswordShow(type) {
    if (type == 'passwordSign') {
      this.passwordSign_show = !this.passwordSign_show;
    }
    else if (type == 'confirmPassSign') {
      this.confirmPassSign_show = !this.confirmPassSign_show;
    }
  }

  completeUserData() {
    if (this.myForm.valid) {
      this.snedEmailLoading = true;
      let body = {
        type:'sign_up',
        email:this.myForm.get('email').value
      }
      this.httpCustomService.post('users.php?action=sendEmailCode', body).subscribe({
        next:(res)=> {
          this.curentStep+=1;
          this.myAppUtilfunsService.AppToast('تم إرسال الكود الي الايميل الخاص بك', 3000, 's');
        },
        error:(err)=> {
          
        },
        complete:()=> {
          this.snedEmailLoading = false;
        }
      });
    }
    else {
      this.myForm.markAllAsTouched();
      this.myAppUtilfunsService.AppToast('أكمل إدخال البيانات', 3000, 'e');
    }
  }
  checkEmail(e:any) {

    this.emailAlreadyUse = false;

    let body = {
      email:e.target.value
    }

    this.httpCustomService
    .post("users.php?action=cheackUserByMobile", body).subscribe({
      next:(res)=> {
        console.log("res flag ! next ...");
        this.emailAlreadyUse = true;
        if (res && res.message == 'لا يوجد حساب مرتبط بالبريد الالكترونى') {
          this.emailAlreadyUse = false;
        }
      },
      error:(err)=> {
        this.emailAlreadyUse = false;
      }
    })
  }



  changeFocus(value, order) {
    //console.log('================>', value, order);


    if (value) {
      if (order == "input1") {
        this.input2.setFocus();
      }
      else if (order == "input2") {
        this.input3.setFocus();
      }
      else if (order == "input3") {
        this.input4.setFocus();
      }
      else if (order == "input4") {
        this.input5.setFocus();
      }
      else if (order == "input5") {
        this.input6.setFocus();
      }

    } else {
      if (order == "input2") {
        this.input1.setFocus();
      }
      else if (order == "input3") {
        this.input2.setFocus();
      }
      else if (order == "input4") {
        this.input3.setFocus();
      }
      else if (order == "input5") {
        this.input4.setFocus();
      }
      else if (order == "input6") {
        this.input5.setFocus();
      }

    }

  }

  confirmSignUpCodeMobile() {

    let code = this.verifyDigitSt.value + "" + this.verifyDigitNd.value + "" + this.verifyDigitRd.value + "" + this.verifyDigitTh.value + "" + this.verifyDigitf.value + "" + this.verifyDigits.value;
    code = this.myAppUtilfunsService.englishNumbersFormat(code);


    this.confirmSignUpCodeMobileLoader = true;

    if(code.toString().length === 6) {
      let info = this.login
      info['code'] = "" + code
      info['email'] = "" + this.myForm.get('email').value;
      this.httpCustomService.post("users.php?action=confirmSignUpCodeEmail", info).subscribe({
        next:(res)=> {
          if (res.success) {
            this.CommonApiService.completeUserData(this.myForm.value).subscribe({
              next:(res)=> {
                if (res && res.data) {
                  localStorage.setItem('authentication_code', res.data.authentication_code);
                  localStorage.setItem('userInfo', JSON.stringify(res.data));
                  this.dataStoreService.authentication_code = res.data.authentication_code;
                  
      
                  if (res.data.user_type == 'student') {
                    this.navController.navigateForward(['/tabs/student-profile'])
                    this.myAppUtilfunsService.AppToast('تم تسجيل الاشتراك بنجاح', 4000, 's');
                  }
                  else if (res.data.user_type == 'teacher') {
                    this.navController.navigateForward(['/tabs/teacher-profile'])
                    this.myAppUtilfunsService.AppToast('تم تسجيل الاشتراك بنجاح', 4000, 's');
                  }
               
                }

                if (res && res.message) {
                  if (res.message == "البريد الالكترونى المحدد قيد الاستخدام بالفعل") {
                    this.myAppUtilfunsService.AppToast(res.message, 4000, 'e');
                  } 
                  this.confirmSignUpCodeMobileLoader = false;
                }
              },
              error:(err)=> {
                console.log("err => ", err)
              }
            })
          }
          else {
            this.myAppUtilfunsService.AppToast('كود التفعيل غير صحيح', 3000, 'e');
          }
        },
        error:(err)=> {
          console.log("err flag => ", err);
          this.confirmSignUpCodeMobileLoader = false;
        },
        complete:()=> {
          this.snedEmailLoading = false;
        }
      })
    }
    else {
      this.myAppUtilfunsService.AppToast('ادخل جميع الخانات للكود', 3000, 'e');
      this.confirmSignUpCodeMobileLoader = false;
    }
  }
  
}
