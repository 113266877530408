import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompletedataService {

  showCompleteData = new BehaviorSubject<boolean>(false);
  constructor() { }

  setShowCompleteData() {
    this.showCompleteData.next(true);
  }
  hideCompleteDara() {
    this.showCompleteData.next(false);
  }
  
}
