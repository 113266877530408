import { Inject, Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { NotificationDetailsPage } from '../pages/common-pages/notification-details/notification-details.page';
import { HttpCustomService } from './http-custom.service';
import { USERTYPES } from './interfaces';
import { Socket } from 'ngx-socket-io';
import { MyAppUtilfunsService } from './my-app-utilfuns.service';
import { TranslateService } from '@ngx-translate/core';
import { CompletedataService } from '../shared/services/completedata.service';


@Injectable()
export class DataStoreService {
  //server

  private appComponentSubject = new Subject<any>();
  private testDetailsSubject = new Subject<any>();
  private postDetailsSubject = new Subject<any>();

  private testListStundets = new Subject<any>();

  private testListTeacher = new Subject<any>();


  private playAudio = new Subject<any>();

  relatedAccountcaching: any = [];

  currunt_video_plaing: any
  currunt_audio_plaing: any
  array_of_audios: any = [];


  public API_URL: string;
  public UPLOAD_FOLDER: string;
  public API_URL_CONST: string;
  public API_URL_Print: string;

  public authentication_code: string;
  public lang: string;
  public unReadNotificationNum: any;
  public userInfo: any = {};

  // refactoring Services
  public countries: any = []
  public pages: any = []
  public advantages: any = []
  public explanations: any = []
  public groups: any = []
  public student_subjects: any = []
  public teacher_subjects: any = []
  public signin_method: any
  public unit_of_subjects: any = []
  public unit_of_subjects_with_lessons: any = []
  public settings: any = {}
  // refactoring Services
  public params: any
  public env = environment;
  public USERTYPES = USERTYPES

  public app_version_number: string;
  public speech_text: any;
  public startspeech: boolean;
  public deepLinkDomain = 'link.testat-app.com';
  public domainLink = 'testat-app.com';
  public accessToken: any
  device_data: any = {}// new for event and listen event
  

  constructor(public navController: NavController,
    public platform: Platform,
    private modalController: ModalController,
    private httpCustomService: HttpCustomService,
    private socket: Socket,public myAppUtilfunsService:MyAppUtilfunsService,
    public translateService:TranslateService,
    private _CompletedataService:CompletedataService
  ) {

    this.startspeech = true;
    this.API_URL = this.env.api_link + this.env.api_version;
    this.API_URL_CONST = this.env.api_link + "api-const-new/";
    this.API_URL_Print = this.env.api_link + "print/";
    this.UPLOAD_FOLDER = this.env.api_link + "uploads/";
    // this.socket.on('connect', () => console.log(this.socket.ioSocket.id));
  }

  
  async initUserSomeData() {
    this.userInfo = await this.localStorage().getObject('userInfo')
    this.authentication_code = await this.localStorage().get('authentication_code')
  }

  setUserData(data) {
    // this.socket.disconnect();
    this.userInfo = data
    this.localStorage().setObject('userInfo', this.userInfo);

    this.socket.connect();
    console.log("is connected? " , this.socket.ioSocket);

  var userdata= data;
  console.log("userdata===",userdata);
  this.socket.emit('userconnect',{user_from : 'app', full_name:userdata.full_name,email:userdata.email,user_id:userdata.id,image:userdata.img,user_type:userdata.user_type,stage_id:userdata.stage_id,grade_id:userdata.grade_id,grade_name:userdata.grade_name,stage_name:userdata.stage_name});

    if (data.authentication_code) {
      this.authentication_code = data.authentication_code
      this.localStorage().set('authentication_code', this.authentication_code);
    }
    // this.getSettingsData();

  }

  removeUserData() {
    this.socket.disconnect();

    this.userInfo = {}
    this.authentication_code = ''
    localStorage.removeItem('userInfo')
    localStorage.removeItem('authentication_code')


    this.resetAllRelatedAccountData()
    this.navController.navigateRoot('/login');

  }


  resetAllRelatedAccountData() {
    this.unReadNotificationNum = 0
    this.groups = []
    this.student_subjects = []
    this.teacher_subjects = []
    this.unit_of_subjects = []
    this.unit_of_subjects_with_lessons = []
  }

  localStorage() {
    return {
      set: function (key, value) {
        window.localStorage[key] = value;
      },
      get: function (key) {
        return window.localStorage[key] || '';
      },
      setObject: function (key, value) {
        window.localStorage[key] = JSON.stringify(value);
      },
      getObject: function (key) {
        return JSON.parse(window.localStorage[key] || '{}');
      }
    };
  }


  async navigateNotifcation(notification_id, type, redirect_data,scheduled_date, parse =false) {
  
    
    // redirect_data {"group_id":"1360","request_id":"105006"}
    if(scheduled_date== null || scheduled_date==true || (scheduled_date <  this.myAppUtilfunsService.getDateTimetoSetFormat4()) ) {
    this.readNotification(notification_id)

    if (!this.userInfo.id) {
      this.navController.navigateRoot('login')
      return
    }
    else if (type == 'addMemberToGroup'
      || type == 'joinGroupRequest'
      || type == 'exitGroup'
      || type == 'acceptJoinGroupRequest'
      || type == 'rejectJoinGroupRequest') {
        // if(type=="rejectJoinGroupRequest" || type=="acceptJoinGroupRequest")
        // {
        // redirect_data = JSON.parse(redirect_data);
        // }
      if (type == 'joinGroupRequest') {
        this.goToGroup({ 'id': redirect_data.group_id }, redirect_data.request_id)
      }
       else {

        this.goToGroup({ 'id': redirect_data.group_id })
      }
    }

    else if (type == 'testFinished') {
      this.navController.navigateForward(['/solved-test/' + redirect_data.student_tests_answers_id]);
    }
    else if (type == 'testCorrected' || type == 'childTestCorrected') {
      this.navController.navigateForward(['/test-result/' + redirect_data.student_tests_answers_id], { replaceUrl: false });
    }
    else if (type == 'shareTestForStudent') {
      // redirect_data = JSON.parse(redirect_data);

      this.goToTest({ 'id': redirect_data.test_id, 'share_id': redirect_data.share_id })
    }
    else if (type == 'shareTestOnGroup' || type == 'addNewPost') {
      // redirect_data = JSON.parse(redirect_data);

      this.goToPost({ 'id': redirect_data.post_id })
    }
    else if (type == 'inquiryReply') {
      let navigationExtras: NavigationExtras = {
        state: {
          'title': redirect_data.title,
          'id': redirect_data.video_id,
          "came_form_root_type": "notification",
          "type": type,
          "comment_id": redirect_data.id


        }
      };
      this.navController.navigateForward(['/tabs/student-profile/ex-video'], navigationExtras)
    }
    else if (type == 'commentReply') {
      let navigationExtras: NavigationExtras = {
        state: {
          'title': redirect_data.title,
          'id': redirect_data.video_id,
          "came_form_root_type": "notification",
          "type": type,
          "comment_id": redirect_data.id

        }
      };
      this.navController.navigateForward(['/tabs/student-profile/ex-video'], navigationExtras)
    }
    else if (type == 'subscriptionTermination2days' || type == 'subscriptionTermination7days') {
      console.log("subscriptionTermination2days");
      if (this.showSubsicripe()) {
        this.navController.navigateForward('prices')
      }
    }
    else if (type == 'adminNotification') {


      this.navController.navigateForward(['/notification-details/' + notification_id])
    }
    else if (type == 'userSubscription'){
      this.navController.navigateForward('subscriptions')
    }
    else if (type == 'ActivateVideoCourse') {
      let navigationExtras: NavigationExtras = {
        state: {
          'title': 'title',
          'id':redirect_data.video_id,
          "came_form_root_type":'this.came_form_root_type',
          "course_data":{}
        }
      };
      this.navController.navigateForward(['/tabs/student-profile/ex-video'], navigationExtras);
    }
  }
  else
    {
     this.myAppUtilfunsService.AppToast(this.translateService.instant("L_waitScheduledDate"))
    }
  }




  async navigatePushNotifcation(notification_id, type, redirect_data,scheduled_date, parse =false) {
    console.log("Type###########################", JSON.parse(redirect_data));
    // redirect_data {"group_id":"1360","request_id":"105006"}
    redirect_data = JSON.parse(redirect_data);

    this.readNotification(notification_id)

    if (!this.userInfo.id) {
      this.navController.navigateRoot('login')
      return
    }
    else if (type == 'addMemberToGroup'
      || type == 'joinGroupRequest'
      || type == 'exitGroup'
      || type == 'acceptJoinGroupRequest'
      || type == 'rejectJoinGroupRequest') {
        // if(type=="rejectJoinGroupRequest" || type=="acceptJoinGroupRequest")
        // {
        // }
      if (type == 'joinGroupRequest') {
        this.goToGroup({ 'id': redirect_data.group_id }, redirect_data.request_id)
      }
       else {

        this.goToGroup({ 'id': redirect_data.group_id })
      }
    }

    else if (type == 'testFinished') {
      this.navController.navigateForward(['/solved-test/' + redirect_data.student_tests_answers_id]);
    }
    else if (type == 'testCorrected' || type == 'childTestCorrected') {
      this.navController.navigateForward(['/test-result/' + redirect_data.student_tests_answers_id], { replaceUrl: false });
    }
    else if (type == 'shareTestForStudent') {
      // redirect_data = JSON.parse(redirect_data);

      this.goToTest({ 'id': redirect_data.test_id, 'share_id': redirect_data.share_id })
    }
    else if (type == 'shareTestOnGroup' || type == 'addNewPost') {
      // redirect_data = JSON.parse(redirect_data);

      this.goToPost({ 'id': redirect_data.post_id })
    }
    else if (type == 'inquiryReply') {
      let navigationExtras: NavigationExtras = {
        state: {
          'title': redirect_data.title,
          'id': redirect_data.video_id,
          "came_form_root_type": "notification",
          "type": type,
          "comment_id": redirect_data.id


        }
      };
      this.navController.navigateForward(['/tabs/student-profile/ex-video'], navigationExtras)
    }
    else if (type == 'commentReply') {
      let navigationExtras: NavigationExtras = {
        state: {
          'title': redirect_data.title,
          'id': redirect_data.video_id,
          "came_form_root_type": "notification",
          "type": type,
          "comment_id": redirect_data.id

        }
      };
      this.navController.navigateForward(['/tabs/student-profile/ex-video'], navigationExtras)
    }
    else if (type == 'subscriptionTermination2days' || type == 'subscriptionTermination7days') {
      console.log("subscriptionTermination2days");
      if (this.showSubsicripe()) {
        this.navController.navigateForward('prices')
      }
    }
    else if (type == 'adminNotification') {


      this.navController.navigateForward(['/notification-details/' + notification_id])
    }

  }

  readNotification(notification_id) {
    this.httpCustomService.post('notifications.php?action=readNotification', { 'notification_id': notification_id })
      .subscribe((res: any) => {
        if (res.success) {
          this.unReadNotificationNum = res.data;
        }
      })

  }

  checkedNotification(){
    return this.httpCustomService.get('notifications.php?action=checkedNotification') 
    .subscribe((res: any) => {
      if (res.success) {
        this.unReadNotificationNum = res.data;
      }
    })
  }

  goToPost(post) {
    this.navController.navigateForward('post/' + post.id);
  }
  async goToGroup(group, req_id?: number | string) {

    const modal = await this.modalController.getTop();
    if (modal) {
      this.modalController.dismiss()
    }
    let hash = req_id ? "#" + req_id : ""
    console.log(hash)
    console.log('group/' + group.id + hash)
    this.navController.navigateForward('group/' + group.id + hash);
  }


  goToGroups(came_form_root_type,) {

    let userData = JSON.parse(localStorage.getItem('userInfo'))

    if (came_form_root_type == 'group_list_teacher' && userData.without_email == '1') {
      // this._CompletedataService.setShowCompleteData();
      this.navController.navigateForward('completedata');
      return;
    }
    let navigationExtras: NavigationExtras = {
      state: {
        'came_form_root_type': came_form_root_type
      }
    };
    this.navController.navigateForward(['tabs/' + this.userInfo.user_type + '-profile/my-groups-list'], navigationExtras);

  }

  
  goToTest(test) {
    // if(test.paid=='1' && test.type =="unit_exercise")
    // {
    let navigationExtras: NavigationExtras = {
      state: {
        'test': test,
        "classification":"Timetest"
      }
    };
    this.navController.navigateForward(['test'], navigationExtras);

  }

  public GetPlatform() {
    return this.platform.is('ios') ? 'ios' : 'android';
  }

  
  // showSubsicripe() {

  //   if (this.app_version_number > this.settings.app_IOS_version &&
  //     this.GetPlatform() == 'ios' && this.settings.hide_subsecription == '1') {
  //     return false;
  //   } else {
  //     if (
  //       this.GetPlatform() == 'ios' && !this.settings.app_IOS_version) {
  //       return false;
  //     } else {
  //       return true
  //     }
  //   }
  // }
  
  showSubsicripe() {

    if (this.settings.hide_subsecription == '1') {
      return false;
    } else {
      return true;
    }
  }


  publishAppComponent(data: any) {
    this.appComponentSubject.next(data);
  }

  getObservableAppComponent(): Subject<any> {
    return this.appComponentSubject;
  }

  publishTestDetails(data: any) {
    this.testDetailsSubject.next(data);
  }
  getObservableTestDetails(): Subject<any> {
    return this.testDetailsSubject;
  }

  publishPostDetails(data: any) {
    this.postDetailsSubject.next(data);
  }
  getObservablePostDetails(): Subject<any> {
    return this.postDetailsSubject;
  }



  publishTestListStundets(data: any) {
    this.testListStundets.next(data);
  }
  getObservableTestListStundets(): Subject<any> {
    return this.testListStundets;
  }

  publishTestListTeacher(data: any) {
    this.testListTeacher.next(data);
  }
  getObservableTestListTeacher(): Subject<any> {
    return this.testListTeacher;
  }

  publishPlayAudio(data: any) {
    this.playAudio.next(data);
  }
  getObservablePlayAudio(): Subject<any> {
    return this.playAudio;
  }


}


