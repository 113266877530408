import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DataStoreService } from './data-store.service';
import { MyAppUtilfunsService } from './my-app-utilfuns.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  usersubscription: any;
  constructor(private dataStoreService: DataStoreService,
    private navController: NavController,
    private myAppUtilfunsService: MyAppUtilfunsService,
    private translateService: TranslateService,

  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.dataStoreService.userInfo.id) {


      // if (this.dataStoreService.userInfo.show_msg_to_valid_mobile) {
      //   this.myAppUtilfunsService.customAlert(this.translateService.instant('L_confirmMobileMsg1'), 'logo2.png')
      //   this.navController.navigateForward('confirm-mobile');
      //   return false
      // }else
      if (this.dataStoreService.userInfo.subscription.end_date < this.myAppUtilfunsService.getDateTimetoSetFormat4()) {
        this.myAppUtilfunsService.customAlert(this.translateService.instant('L_finishSubsicription'), 'logo2.png')
        this.navController.navigateForward('prices');
        return false
      }
      else {
        return true
      }
    } else {
      this.navController.navigateRoot('login');
      return false;
    }
  }


}
