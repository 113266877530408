import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./providers/auth.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/common-pages/tabs/tabs.module").then(
        (m) => m.TabsPageModule
      ),
  },
  {
    path: "tabs",
    loadChildren: () =>
      import("./pages/common-pages/tabs/tabs.module").then(
        (m) => m.TabsPageModule
      ),
  },
  {
    path: "intro",
    loadChildren: () =>
      import("./pages/common-pages/intro/intro.module").then(
        (m) => m.IntroPageModule
      ),
  },
  {
    path: "page-details/:page_id",
    loadChildren: () =>
      import("./pages/common-pages/page-details/page-details.module").then(
        (m) => m.PageDetailsPageModule
      ),
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./pages/common-pages/contact-us/contact-us.module").then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/common-pages/auth/login/login.module").then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: "forgetpass",
    loadChildren: () =>
      import("./pages/common-pages/auth/forgetpass/forgetpass.module").then(
        (m) => m.ForgetpassPageModule
      ),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./pages/common-pages/faq/faq.module").then(
        (m) => m.FaqPageModule
      ),
  },
  {
    path: "prices",
    loadChildren: () =>
      import("./pages/common-pages/prices/prices.module").then(
        (m) => m.PricesPageModule
      ),
  },
  {
    path: "all-prices",
    loadChildren: () =>
      import("./pages/common-pages/all-prices/all-prices.module").then(
        (m) => m.AllPricesPageModule
      ),
  },
  {
    path: "app-features/:advantage_id",
    loadChildren: () =>
      import("./pages/common-pages/app-features/app-features.module").then(
        (m) => m.AppFeaturesPageModule
      ),
  },
  {
    path: "subscriptions",
    loadChildren: () =>
      import("./pages/common-pages/subscriptions/subscriptions.module").then(
        (m) => m.SubscriptionPageModule
      ),
  },
  
  {
    path: "payment-methods",
    loadChildren: () =>
      import(
        "./pages/common-pages/payment-methods/payment-methods.module"
      ).then((m) => m.PaymentMethodsPageModule),
  },

  {
    path: "app-explain",
    loadChildren: () =>
      import("./pages/common-pages/app-explain/app-explain.module").then(
        (m) => m.AppExplainPageModule
      ),
  },

  {
    path: "force-update",
    loadChildren: () =>
      import("./pages/common-pages/force-update/force-update.module").then(
        (m) => m.ForceUpdatePageModule
      ),
  },
  {
    path: "answer-explain",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/common-pages/answer-explain/answer-explain.module").then(
        (m) => m.AnswerExplainPageModule
      ),
  },
  {
    path: "teacher-profile/add-test-custom",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./pages/teacher-pages/add-test-custom/add-test-custom.module"
      ).then((m) => m.AddTestCustomPageModule),
  },
  {
    path: "test-result/:student_tests_answers_id",
    // "canActivate": [AuthGuard],
    loadChildren: () =>
      import("./pages/common-pages/test-result/test-result.module").then(
        (m) => m.TestResultPageModule
      ),
  },

  {
    path: "edit-profile",
    loadChildren: () =>
      import("./pages/common-pages/auth/edit-profile/edit-profile.module").then(
        (m) => m.EditProfilePageModule
      ),
  },
  {
    path: "edit-mobile",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/common-pages/auth/edit-mobile/edit-mobile.module").then(
        (m) => m.EditMobilePageModule
      ),
  },

  // {
  //   path: 'confirm-mobile',
  //   loadChildren: () => import('./pages/common-pages/auth/confirm-mobile/confirm-mobile.module').then(m => m.ConfirmMobilePageModule)
  // },

  {
    path: "edit-password",
    loadChildren: () =>
      import(
        "./pages/common-pages/auth/edit-password/edit-password.module"
      ).then((m) => m.EditPasswordPageModule),
  },
  {
    path: "profile-settings",
    loadChildren: () =>
      import(
        "./pages/common-pages/auth/profile-settings/profile-settings.module"
      ).then((m) => m.ProfileSettingsPageModule),
  },
  {
    path: "student-settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./pages/student-pages/student-settings/student-settings.module"
      ).then((m) => m.StudentSettingsPageModule),
  },
  {
    path: "teacher-shares",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/teacher-pages/teacher-shares/teacher-shares.module").then(
        (m) => m.TeacherSharesPageModule
      ),
  },
  {
    path: "group/:group_id",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/common-pages/group/group.module").then(
        (m) => m.GroupPageModule
      ),
  },
  {
    path: "test-result/:student_tests_answers_id",
    // "canActivate": [AuthGuard],
    loadChildren: () =>
      import("./pages/common-pages/test-result/test-result.module").then(
        (m) => m.TestResultPageModule
      ),
  },
  {
    path: "test",
    // "canActivate": [AuthGuard],
    loadChildren: () =>
      import("./pages/student-pages/test/test.module").then(
        (m) => m.TestPageModule
      ),
  },
  {
    path: "post/:post_id",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/common-pages/post/post.module").then(
        (m) => m.PostPageModule
      ),
  },
  {
    path: "test-list-options",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./pages/teacher-pages/test-list-options/test-list-options.module"
      ).then((m) => m.TestListOptionsPageModule),
  },
  {
    path: "add-test-ready",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/teacher-pages/add-test-ready/add-test-ready.module").then(
        (m) => m.AddTestReadyPageModule
      ),
  },
  {
    path: "solved-test/:student_tests_answers_id",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/teacher-pages/solved-test/solved-test.module").then(
        (m) => m.SolvedTestPageModule
      ),
  },
  {
    path: "student-results",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./pages/teacher-pages/student-results/student-results.module"
      ).then((m) => m.StudentResultsPageModule),
  },
  {
    path: "meeting",
    loadChildren: () =>
      import("./pages/common-pages/meeting/meeting.module").then(
        (m) => m.MeetingPageModule
      ),
  },
  {
    path: "create-meet",
    loadChildren: () =>
      import("./pages/common-pages/create-meet/create-meet.module").then(
        (m) => m.CreateMeetPageModule
      ),
  },
  {
    path: "join-meet/:meeting_serial",
    loadChildren: () =>
      import("./pages/common-pages/join-meet/join-meet.module").then(
        (m) => m.JoinMeetPageModule
      ),
  },
  {
    path: "notification-details/:notification_id",
    loadChildren: () =>
      import(
        "./pages/common-pages/notification-details/notification-details.module"
      ).then((m) => m.NotificationDetailsPageModule),
  },
  {
    path: "switch-accounts",
    loadChildren: () =>
      import(
        "./pages/common-pages/auth/switch-accounts/switch-accounts.module"
      ).then((m) => m.SwitchAccountsPageModule),
  },
  {
    path: "create-related-account",
    loadChildren: () =>
      import(
        "./pages/common-pages/auth/create-related-account/create-related-account.module"
      ).then((m) => m.CreateRelatedAccountPageModule),
  },

  {
    path: "semesters",
    loadChildren: () =>
      import("./pages/teacher-pages/semesters/semesters.module").then(
        (m) => m.SemestersPageModule
      ),
  },
  {
    path: "ex-lessons",
    loadChildren: () =>
      import("./pages/student-pages/ex-lessons/ex-lessons.module").then(
        (m) => m.ExLessonsPageModule
      ),
  },
  {
    path: "ex-video",
    loadChildren: () =>
      import("./pages/student-pages/ex-video/ex-video.module").then(
        (m) => m.ExVideoPageModule
      ),
  },
  {
    path: "teacher-video",
    loadChildren: () =>
      import("./pages/teacher-pages/teacher-video/teacher-video.module").then(
        (m) => m.TeacherVideoPageModule
      ),
  },
  {
    path: "add-video",
    loadChildren: () =>
      import("./pages/teacher-pages/add-video/add-video.module").then(
        (m) => m.AddVideoPageModule
      ),
  },
  {
    path: "ex-teacher",
    loadChildren: () =>
      import("./pages/teacher-pages/ex-teacher/ex-teacher.module").then(
        (m) => m.ExTeacherPageModule
      ),
  },
  {
    path: "teacher-guide",
    loadChildren: () =>
      import("./pages/teacher-pages/teacher-guide/teacher-guide.module").then(
        (m) => m.TeacherGuidePageModule
      ),
  },
  {
    path: "teacher-courses",
    loadChildren: () =>
      import(
        "./pages/teacher-pages/teacher-courses/teacher-courses.module"
      ).then((m) => m.TeacherCoursesPageModule),
  },
  {
    path: "add-courses",
    loadChildren: () =>
      import("./pages/teacher-pages/add-courses/add-courses.module").then(
        (m) => m.AddCoursesPageModule
      ),
  },
  {
    path: "fav-courses",
    loadChildren: () =>
      import("./pages/teacher-pages/fav-courses/fav-courses.module").then(
        (m) => m.FavCoursesModule
      ),
  },
  {
    path: "teacher-list",
    loadChildren: () =>
      import("./pages/teacher-pages/teacher-list/teacher-list.module").then(
        (m) => m.TeacherListModule
      ),
  },
  {
    path: "teacher-details",
    loadChildren: () =>
      import(
        "./pages/teacher-pages/teacher-details/teacher-details.module"
      ).then((m) => m.TeacherDetailsPageModule),
  },
  {
    path: "teacher-news",
    loadChildren: () =>
      import("./pages/teacher-pages/teacher-news/teacher-news.module").then(
        (m) => m.TeacherNewsPageModule
      ),
  },
  {
    path: "teacher-notebook",
    loadChildren: () =>
      import(
        "./pages/teacher-pages/teacher-notebook/teacher-notebook.module"
      ).then((m) => m.TeacherNotebookPageModule),
  },
  {
    path: "tools-details",
    loadChildren: () =>
      import("./pages/teacher-pages/tools-details/tools-details.module").then(
        (m) => m.ToolsDetailsPageModule
      ),
  },
  {
    path: "courses-subjects",
    loadChildren: () =>
      import(
        "./pages/teacher-pages/courses-subjects/courses-subjects.module"
      ).then((m) => m.CoursesSubjectsPageModule),
  },
  {
    path: 'add-brief',
    loadChildren: () => import('./pages/teacher-pages/add-brief/add-brief.module').then( m => m.AddBriefPageModule)
  },
  // {
  //   path: 'balance',
  //   loadChildren: () => import('./balance/balance.module').then( m => m.BalancePageModule)
  // },
  {
    path: 'balance-payment',
    loadChildren: () => import('./pages/teacher-pages/balance-payment/balance-payment.module').then( m => m.BalancePaymentPageModule)
  },
  {
    path: 'balance',
    loadChildren: () => import('./pages/teacher-pages/balance/balance.module').then( m => m.BalancePageModule)
  },
  {
    path: 'student-show-all-result',
    loadChildren: () => import('./pages/teacher-pages/student-show-all-result/student-show-all-result.module').then( m => m.StudentShowAllResultPageModule)
  },
  {
    path: 'addoverview',
    loadChildren: () => import('./pages/teacher-pages/addoverview/addoverview.module').then( m => m.AddoverviewPageModule)
  },
  {
    path: 'clientlogin',
    loadChildren: () => import('./pages/common-pages/auth/clientlogin/clientlogin.module').then( m => m.ClientloginPageModule)
  },
  {
    path: 'visitorlogin',
    loadChildren: () => import('./pages/common-pages/auth/visitorlogin/visitorlogin.module').then( m => m.VisitorloginPageModule)
  },
  {
    path: 'completedata',
    loadChildren: () => import('./pages/common-pages/completedata/completedata.module').then( m => m.CompletedataPageModule)
  },
  {
    path: 'reportQues',
    loadChildren: () => import('./components/report-ques/report-ques.module').then( m => m.ReportQuesModule)
  }


];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
